<template>
  <div>
    <section class="main no-flex">
      <OrderItemTrackingTable />
    </section>
  </div>
</template>

<script>
import OrderItemTrackingTable from '../components/OrderItemTrackingTable.vue';

export default {
  name: 'Home',
  components: {
    OrderItemTrackingTable,
  },
}
</script>
<style scoped lang="scss">
.main{
    padding: 87px 15px 15px 110px;
  }
@media screen and (max-width: 1500px){
  .main{
    padding: 87px 15px 15px 90px;
  }
}
@media screen and (max-width: 1190px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
