<template>
  <div>
    <b-sidebar
      id="history-of-orders"
      :title="`${$t('EmployeeTrackAndTrace')}`"
      right
      header-class="headerModalToggle"
      shadow
      no-close-on-esc
      width="50%"
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('EmployeeTrackAndTrace')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;;height: 60px; "
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <b-tabs>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ orderNumber }} - {{ $t(stateOfTrack) }}</strong>
          </template>
          <div class="px-2 py-2">
            <div style="width: 100%;  ">
              <table
                ref="exportable_table"
                class="team_table"
                style="width: 100%; box-shadow: none; font-size: 12px"
              >
                <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
                  <tr>
                    <th style="padding: 10px;color:#262E6C !important; font-size: 15px;">
                      {{ $t('ProcessedBy') }}
                    </th>
                    <th style="padding: 10px;color:#262E6C !important; font-size: 15px;">
                      {{ $t('ExecutorName') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('Created') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('FinishedTime') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('Notes') }}
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr
                    v-for="order in getProcessingActivity.processingUserDtos"
                    :key="order.id"
                  >
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.processingUserName }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.executorName }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ convertUTCDateToLoacalDate(order.created) }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ convertUTCDateToLoacalDate(order.finishedTime) }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.notes }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="display: flex;justify-content: flex-end;margin-top: 15px;gap: 5px;">
                <span style="font-weight: bold;">{{ $t("TotalWorkingHours") }}</span> : <span>{{ formattedDuration(getProcessingActivity.totalWorkingTime) }}</span>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <strong
              style="font-weight: 500; color: black"
            >{{ orderNumber }} - {{ $t("FullHistory") }}</strong>
          </template>
          <div class="px-2 py-2">
            <div style="width: 100%;  ">
              <table
                ref="exportable_table"
                class="team_table"
                style="width: 100%; box-shadow: none; font-size: 12px"
              >
                <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
                  <tr>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('ProcessedBy') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('ExecutorName') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('State') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('Created') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('FinishedTime') }}
                    </th>
                    <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                      {{ $t('Notes') }}
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr
                    v-for="order in getProcessingActivityItemId.processingUserDtos"
                    :key="order.id"
                  >
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.processingUserName }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.executorName }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.state }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ convertUTCDateToLoacalDate(order.created) }}
                    </td>
                    <td
                      v-if="order.finishedTime != null"
                      style="padding: 9px; font-weight: bold; color: black;"
                    >
                      {{ convertUTCDateToLoacalDate(order.finishedTime) }}
                    </td>
                    <td
                      v-else
                      style="padding: 9px; font-weight: bold; color: black;"
                    >
                      {{ order.finishedTime }}
                    </td>
                    <td style="padding: 9px; font-weight: bold; color: black;">
                      {{ order.notes }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style="display: flex;justify-content: flex-end;margin-top: 15px;gap: 5px;">
                <span style="font-weight: bold;">{{ $t("TotalWorkingHours") }}</span> : <span>{{ formattedDuration(getProcessingActivityItemId.totalWorkingTime) }}</span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-sidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {

  },
  props: ['orderItemIdTrack', 'stateOfTrack', 'orderNumber'],
  data() {
    return {
      userObj: {},
      showOtherComponent: false,
      isactive: true,
      searchUser: '',
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      employeeId: null,
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeeFeedbackPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getLanguage',
      'getProcessingActivity',
      'getProcessingActivityItemId',
    ]),
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeeFeedbackPagination.filter((getEmployeeFeedbackPagination) => {
          return getEmployeeFeedbackPagination.userId === this.selectedId;
        })
        : this.getEmployeeFeedbackPagination;
    },
  },
  watch: {
    page(value) {
      if (this.isactive) {
        this.loadEmployeeFeedbackPagination({
          firstName: this.searchUser,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    searchUser(value) {
      this.selectedId = null;
      if (value == '') {
        this.removeLists();
        if (this.isactive) {
          this.loadEmployeeFeedbackPagination({
            firstName: value,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.getInactiveUsersPagination({
            status: false,
            pageNumber: this.page,
            pageSize: this.pageSize,
            fullName: value,
          });
        }
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else if (this.isactive) {
        this.filterActiveUsersByName(value);
      } else {
        this.filterInactiveUsersByName(value);
      }
      this.noSearch = true;
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions([
      'loadEmployeeFeedbackPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadFeedback',
      'loadEmployeeFeedbackPagination',
      'trackProcessingActivity',
      'trackProcessingActivityItemId',
    ]),
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    formattedDuration(value) {
      const duration = moment.duration(value);
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';
      if (hours === 0 && minutes === 0 && seconds === 0) {
        return '0 hours and 0 minutes and 0 seconds';
      }

      if (hours > 0) {
        formattedDuration += `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
      }

      if (minutes > 0) {
        formattedDuration += `${hours > 0 ? ' and ' : ''}${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
      }

      if (seconds > 0) {
        formattedDuration += `${(hours > 0 || minutes > 0) ? ' and ' : ''}${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
      }

      return formattedDuration;
    },
    trackState() {
      this.trackProcessingActivity({
        currentState: this.stateOfTrack,
        orderItemId: this.orderItemIdTrack,
      })
    },
    trackFullHistory() {
      this.trackProcessingActivityItemId({
        orderItemId: this.orderItemIdTrack,
      })
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    convertUTCDateToLoacalDate(date) {
      const utcTime = moment.utc(date).format('DD-MMM-YYYY HH:mm');
      return utcTime;
    },

    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
    margin-right: auto;
    border: 0 !important;
    background: white;
}
/* .button {
  width: 195px;
} */

/* .viewAllBtn{
  background: #FF274F;
}
.addEmployeeButton {
  width: 210px;
} */

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}

.dropdrop::-webkit-scrollbar {
  display: none;
}
span{
  color: black;
}
p{
  color: black;
}
table td{
  color: black;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
